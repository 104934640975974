import React, { useState } from 'react';
import PropTypes from 'prop-types';

const defaultPaths = [
  'M 5 3 C 7.773 -0.821 16.184 0.806 18 5 C 19.816 9.194 15.799 9.987 15 12 C 14.201 14.013 15.331 16.994 16 19 C 16.669 21.006 17.538 27.011 12 27 C 6.462 26.989 5.904 21.611 6 18 C 6.096 14.389 2.227 6.821 5 3 Z',
  'M 5 3 C 6.672 0.927 14.184 -0.194 16 4 C 17.816 8.194 13.799 11.987 13 14 C 12.201 16.013 13.331 18.994 14 21 C 14.669 23.006 13.538 27.011 8 27 C 2.462 26.989 0.426 18.558 1 13 C 1.574 7.442 3.328 5.073 5 3 Z',
];

const AnimatedShape = ({ name, paths, originalWidth, originalHeight, fill }) => {

  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${originalWidth / 20} ${originalHeight / 20}`}>
      <defs>
        <pattern id="polka-dots" x="0" y="0" width="1.05" height="1.05" patternUnits="userSpaceOnUse">
          <circle cx="0.38175" cy="0.38175" r="0.12725" fill="rgb(216,216,216)" />
        </pattern>
      </defs>
      <g clipPath={`url(#${name}-shape)`}>
        <rect x="0" y="0" width="100%" height="100%" fill={fill} />
      </g>
    </svg>
  );
};

AnimatedShape.propTypes = {
  name: PropTypes.string.isRequired,
  paths: PropTypes.arrayOf(PropTypes.string),
  originalWidth: PropTypes.number,
  originalHeight: PropTypes.number,
  fill: PropTypes.string,
};

AnimatedShape.defaultProps = {
  paths: defaultPaths,
  originalWidth: 380,
  originalHeight: 550,
  fill: 'url(#polka-dots)',
};

export default AnimatedShape;
